import CryptoJS from 'crypto-js';

export const formatTokenEnum = (enumValue: string): string => {
  // Check if the string contains underscores
  if (enumValue.includes('_')) {
    return enumValue
      .split('_')
      .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
      .join(' ');
  } else {
    // If no underscores, handle as a single word
    return enumValue.charAt(0) + enumValue.slice(1).toLowerCase();
  }
};
export const getInfuraRpcNetwork = (network: string): string => {
  switch (network) {
    case 'arbitrum':
      return 'https://arbitrum-mainnet.infura.io/v3/7e36059f2fc44c46b47aa0ca4a1eed7c';
    case 'optimism':
      return 'https://optimism-mainnet.infura.io/v3/7e36059f2fc44c46b47aa0ca4a1eed7c';
    case 'polygon':
      return 'https://polygon-mainnet.infura.io/v3/7e36059f2fc44c46b47aa0ca4a1eed7c';
    case 'ethereum':
      return 'https://mainnet.infura.io/v3/7e36059f2fc44c46b47aa0ca4a1eed7c';
    case 'eth_sepolia':
      return 'https://sepolia.infura.io/v3/7e36059f2fc44c46b47aa0ca4a1eed7c';
    case 'base':
      return 'https://base-mainnet.infura.io/v3/7e36059f2fc44c46b47aa0ca4a1eed7c';
    default:
      throw new Error('Unsupported network');
  }
};

const keySize = 256;
const iterations = 100;

export function encryptData(value: string, secret: string) {
  const salt = CryptoJS.lib.WordArray.random(128 / 8);

  const key = CryptoJS.PBKDF2(secret, salt, {
    keySize: keySize / 32,
    iterations,
  });

  const iv = CryptoJS.lib.WordArray.random(128 / 8);

  // Encrypt the session
  const encrypted = CryptoJS.AES.encrypt(value, key, {
    iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });

  const transitmessage = salt.toString() + iv.toString() + encrypted.toString();
  return transitmessage;
}

export function decryptData(transitmessage: string, secret: string): string {
  // Extract the salt, iv, and encrypted message from the transit message
  const salt = CryptoJS.enc.Hex.parse(transitmessage.substr(0, 32));
  const iv = CryptoJS.enc.Hex.parse(transitmessage.substr(32, 32));
  const encrypted = transitmessage.substring(64);

  // Derive the key using PBKDF2 with the same salt and secret
  const key = CryptoJS.PBKDF2(secret, salt, {
    keySize: keySize / 32,
    iterations,
  });

  // Decrypt the message
  const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
    iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });

  // Convert the decrypted message to a string
  return decrypted.toString(CryptoJS.enc.Utf8);
}
