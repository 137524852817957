/* eslint-disable @typescript-eslint/no-unused-vars */
interface HttpClientConfig {
  baseUrl: string;
}

export class RabotHttpClient {
  constructor(
    private config: HttpClientConfig,
  ) {}
  // -------------------------------PUBLIC--------------------------------- //

  public get<T>(url: string, headers?: HeadersInit): Promise<T> {
    return this.request<T>(url, {
      method: 'GET',
    });
  }

  public post<T, U>(url: string, body: U, headers?: HeadersInit): Promise<T> {
    return this.request<T>(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public postDialogs<T, U>(url: string, body: any, headers?: HeadersInit): Promise<T> {
    return this.request<T>(url, {
      method: 'POST',
      headers: {
      },
      body,
    });
  }

  public patch<T, U>(url: string, body: U, headers?: HeadersInit): Promise<T> {
    return this.request<T>(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
  }

  public delete<T>(url: string, headers?: HeadersInit): Promise<T> {
    return this.request<T>(url, {
      method: 'DELETE',
    });
  }

  // -------------------------------PRIVATE--------------------------------- //

  private async request<T>(url: string, options: RequestInit, retry = true): Promise<T> {
    const response = await fetch(`https://api-tmp.rabotics.pluto.buidl.so${url}`, options);
    let result: T;

    try {
      result = await response.json();
    } catch (error) {
      result = {} as T;
    }

    if (!response.ok) {
      const error = result as { message: string };
      throw new Error(error.message || 'Something went wrong');
    }
    return result as T;
  }
}
