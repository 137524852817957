import { z } from 'zod';

import { RabotEnum } from '../../../enums';

export const CreateUserBotReqTransformer = z.object({
  userId: z.string(),
  rabotId: z.string(),
  rabbleWalletAddress: z.string(),
});

export const CreateUserBotResTransformer = z.object({
  smartWalletAddress: z.string().optional(),
});

export const DepositToUserBotReqTransformer = z.object({
  userId: z.string(),
  botId: z.string(),
  botType: z.nativeEnum(RabotEnum),
  amount: z.number(),
});

export const WithdrawFromUserBotReqTransformer = z.object({
  userId: z.string(),
  botId: z.string(),
  botType: z.nativeEnum(RabotEnum),
  amount: z.number(),
});

export const GetTotalHoldingsResTransformer = z.object({
  totalHoldings: z.number(),
});

export const GetTotalHoldingDistributionResTransformer = z.object({
  balance: z.number(),
});
