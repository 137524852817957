import { z } from 'zod';

import { UserTierEnum } from '../../utils/calculateUserStats';

export const UploadEarnStatsReqTransformer = z.object({
  payload: z.string(),
});

export const AirdropEarnNftReqTransformer = z.object({
  category: z.string().optional(),
});

export const GetEarnStatsResTransformer = z.object({
  // tgDmCount: z.coerce.number().optional(),
  // tgBotsCount: z.coerce.number().optional(),
  // tgGroupsCount: z.coerce.number().optional(),
  // tgChannelCount: z.coerce.number().optional(),
  tier: z.nativeEnum(UserTierEnum).optional(),
  earnScore: z.coerce.number().optional(),
});

export const Leaderboard = z.object({
  firstName: z.string(),
});

export const GetLeaderboardResTransformer = z.array(Leaderboard);

export const GetLoginStreakResTransformer = z.object({
  loginStreak: z.coerce.number(),
});

export const GetUserRankResTransformer = z.object({
  rank: z.coerce.number(),
});

export const GetInviteAcceptedCountResTransformer = z.object({
  inviteAcceptedCount: z.coerce.number(),
});
