/* eslint-disable @typescript-eslint/comma-dangle */
/* eslint-disable max-len */
import { z } from 'zod';

import type { HttpClient } from '../http';
import type { TCreateRabbleUserResDto, TGetRabbleUserResDto } from './dtos';

import { NetworkEnum } from '../enums';
import { SourceEnum } from '../enums/source.enum';
import { CreateRabbleUserResTransformer, GetRabbleUserResTransformer } from './transformers';

export const TransferCryptoCurrencyReqTransformer = z.object({
  from: z.string(),
  to: z.string(),
  amount: z.number(),
  asset: z.string(),
  network: z.nativeEnum(NetworkEnum),
});

export const TransferCryptoCurrencyResTransformer = z.object({
  hash: z.string(),
});

export type TTransferCryptoCurrencyReqDto = z.infer<
  typeof TransferCryptoCurrencyReqTransformer
>;

export type TTransferCryptoCurrencyResDto = z.infer<
  typeof TransferCryptoCurrencyResTransformer
>;

export class UserService {
  constructor(private readonly httpClient: HttpClient) {}

  // -------------------------------PUBLIC--------------------------------- //

  public async createUser(
    telegramId:string,
    firstName?:string,
    lastName?:string,
    username?:string,
    rabbleEthWallet?:string,
    referrer?:string
  ) : Promise<TCreateRabbleUserResDto> {
    const payload: any = {
      telegramId,
      source: 'WEB',
      rabbleEthWallet,
      firstName,
      lastName,
      username,
    };

    // Add referrer only if it's defined
    if (referrer) {
      payload.referrer = referrer;
    }

    const result = await this.httpClient.post('/rabble-user', payload);
    return CreateRabbleUserResTransformer.parseAsync(result);
  }

  public async getMe() :Promise<TGetRabbleUserResDto> {
    const result = await this.httpClient.get('/rabble-user/me');
    return GetRabbleUserResTransformer.parseAsync(result);
  }

  public async getAllNetworksWalletBalance(
    walletAddress:string,
  ):Promise<any> {
    const result = await this.httpClient.get(`/rabble-user/all-balances-by-address?walletAddress=${walletAddress}`);
    return result;
  }

  public getWalletAddressByTelegramId(telegramId: string): Promise<{
    rabbleEthWallet: string;
    rabbleSolWallet: string;
  }> {
    return this.httpClient.get(`/rabble-user/wallet?telegramId=${telegramId}`);
  }

  public uploadUserDialogs(dto: {
    telegramId: string;
    file: any;
  }): Promise<any> {
    const formData = new FormData();
    formData.append('dialogs', dto.file);

    return this.httpClient.postDialogs('/uploads/dialogs', formData, {
      'x-telegram-id': dto.telegramId,
      'x-source': SourceEnum.WEB,
    },);
  }
}
