import type { RabotHttpClient } from '../rabot-http';
import type {
  TCreateRabotTransactionReqDto,
  TCreateUserBotReqDto, TCreateUserBotResDto, TGetRabotResDto, TListRabotsResDto,
} from './dtos/rabots';

import {
  CreateUserBotResTransformer,
  GetRabotResTransformer, ListRabotsResTransformer,
} from './transformers';

export class RabotService {
  constructor(private readonly httpClient: RabotHttpClient) {}

  public async getAllRabots() :Promise<TListRabotsResDto> {
    const result = await this.httpClient.get('/rabots');
    return ListRabotsResTransformer.parseAsync(result);
  }

  public async getRabotbyId(
    rabotId:string,
    userId:string,
  ):Promise<TGetRabotResDto> {
    const result = await this.httpClient.get(`/rabots/${rabotId}/${userId}`);

    return GetRabotResTransformer.parseAsync(result);
  }

  public async createUserRabotBot(
    payload: TCreateUserBotReqDto,
  ): Promise<TCreateUserBotResDto> {
    const result = await this.httpClient.post('/user-bots', payload);

    return CreateUserBotResTransformer.parseAsync(result);
  }

  public async createTrnsaction(
    payload: TCreateRabotTransactionReqDto,
  ): Promise<void> {
    await this.httpClient.post('/transactions', payload);
  }
}
