import { z } from 'zod';

import { TransactionOwnerEnum } from '../../../enums';

export const CreateRabotTransactionReqTransformer = z.object({
  txHash: z.string(),
  from: z.nativeEnum(TransactionOwnerEnum),
  to: z.nativeEnum(TransactionOwnerEnum),
  fromAddress: z.string(),
  toAddress: z.string(),
  amount: z.number(),
  gas: z.number().optional(),
  currency: z.string().optional(),
  network: z.string().optional(),
});
