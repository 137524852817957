import type { HttpClient } from '../http';

import { GetPrivateKeyResTransformer } from './transformers';

type TGetPrivateKeyReqDto = {
  passcode: string;
};
type TGetPrivateKeyResDto = {
  privateKey: string;
};

export class WalletSettingsService {
  constructor(private readonly httpClient: HttpClient) {}

  public async getPrivateKey(dto: TGetPrivateKeyReqDto) {
    const result = await this.httpClient.patch('/wallets/private-key', dto);
    return result;
  }

  public createPasscode(dto: { passcode: string }): Promise<void> {
    return this.httpClient.post('/wallets/passcode', dto);
  }
}
