import { z } from 'zod';

import { AssetEnum } from '../../enums';
import { SourceEnum } from '../../enums/source.enum';

// Create User Wallet //
export const CreateRabbleUserReqTransformer = z.object({
  telegramId: z.string(),
  source: z.nativeEnum(SourceEnum),
  rabbleEthWallet: z.string(),
  rabbleSolWallet: z.string().optional(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  username: z.string().optional(),
});

export const CreateRabbleUserResTransformer = z.object({
  accessToken: z.string(),
  refreshToken: z.string(),
});

// Get User Wallet //
export const GetUserWalletReqTransformer = z.object({
  telegramId: z.string(),
});

export const GetRabbleUserReqTransformer = z.object({
  telegramId: z.string(),
});

export const TelegramUserTransformer = z.object({
  telegramId: z.string(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  username: z.string().nullable(),
});

export const GetRabbleUserResTransformer = z.object({
  id: z.string(),
  rabbleEthWallet: z.string().nullable(),
  rabbleSolWallet: z.string().nullable(),
  inviteCode: z.string().nullable(),
  source: z.string(),
  telegramUser: TelegramUserTransformer,
});

// Get Balance //
export const GetBalanceResTransformer = z.record(
  z.nativeEnum(AssetEnum),
  z.number().nullable(),
);

// Save Passcode //
export const SavePasscodeReqTransformer = z.object({
  passcode: z.string(),
});

// Verify Passcode //
export const VerifyPasscodeReqTransformer = z.object({
  passcode: z.string(),
});

// Get Privatekey //
export const GetPrivatekeyReqTransformer = z.object({
  passcode: z.string(),
});
export const GetPrivatekeyResTransformer = z.object({
  privateKey: z.string(),
});

export const GetWalletAddressByTelegramIdResTransformer = z.object({
  rabbleEthWallet: z.string().nullable(),
  rabbleSolWallet: z.string().nullable(),
});
