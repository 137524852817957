import { z } from 'zod';

const RabotResTransformer = z.object({
  id: z.string(),
  name: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
  logo: z.string().optional().nullable(),
  cta: z.string().optional().nullable(),
  network: z.string().optional().nullable(),
  userBotWalletAddress: z.string().optional().nullable(),
  userBotSmartWalletAddress: z.string().optional().nullable(),
  updatedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional().nullable(),
});

export const CreateRabotReqTransformer = z.object({
  name: z.string(),
  description: z.string().optional().nullable(),
  logo: z.string().optional().nullable(),
  cta: z.string().optional().nullable(),
  network: z.string().optional().nullable(),
});

export const ListRabotsResTransformer = z.array(RabotResTransformer);

export const GetRabotResTransformer = RabotResTransformer;
