import mixpanel from 'mixpanel-browser';
import { getGlobal } from '../../global';

import type { TGetRabbleUserResDto } from './services/dtos';

import { updateRabbleUserMeInfo } from '../../global/reducers';
import { getQueryParam } from './utils/getQueryParams';
import { EventEnum } from './enums';
import { HttpClient } from './http';
import Logger from './logger';
import { RabotHttpClient } from './rabot-http';
import { Services } from './services';
import { LocalStorageService } from './storage';

export class Pluto {
  private logger: Logger;

  private localStorage: LocalStorageService;

  public services: Services;

  public wallet ?: TGetRabbleUserResDto;

  constructor() {
    this.logger = new Logger('Pluto');
    this.localStorage = new LocalStorageService();
    const accessToken = this.getAccessTokenFromStorage();
    const refreshToken = this.getRefreshTokenFromStorage();
    this.services = this.initServices(accessToken, refreshToken);
  }

  public async connect(
    telegramId:string,
    firstName?:string,
    lastName?:string,
    username?:string,
    rabbleEthWallet?:string,
  ) : Promise<any> {
    try {
      const wallet = await this.services.user.getMe();
      this.setWallet(wallet);
      const global = getGlobal();
      updateRabbleUserMeInfo(global, {
        ...wallet,
      });
      mixpanel.track('Get Me Success', {
        use: 'TECH',
      });
      return wallet;
    } catch (error) {
      const referrer = getQueryParam('referrer');

      const token = await this.services.user.createUser(
        telegramId,
        firstName,
        lastName,
        username,
        rabbleEthWallet,
        referrer || undefined,
      );

      mixpanel.track('Create User Success', {
        use: 'TECH',
      });
      this.resetAuthTokens({
        accessToken: token.accessToken,
        refreshToken: token.refreshToken,
      });
      await this.services.earn.createEvent({ event: EventEnum.LOGIN_0 });
      const wallet = await this.services.user.getMe();
      await this.services.realm.createUser({
        id: telegramId,
        firstName: firstName || '',
        lastName: lastName || '',
        username: username || '',
        isBot: false,
      });
      mixpanel.track('Create Ream User Node', {
        use: 'TECH',
      });
      this.setWallet(wallet);
      const global = getGlobal();
      updateRabbleUserMeInfo(global, {
        ...wallet,
      });
      return wallet;
    }
  }

  // -------------------------------PRIVATE--------------------------------- //

  private createHttpClient(
    accessToken?: string,
    refreshToken?: string,
  ) {
    const baseUrl = process.env.SERVER_URL!;
    return new HttpClient({
      baseUrl,
      accessToken,
      refreshToken,
      onTokenRefresh: this.resetAuthTokens.bind(this),
    });
  }

  private createRabotHttpClient(
  ) {
    const baseUrl = process.env.RABOTICS_SERVER_URL!;
    return new RabotHttpClient({ baseUrl });
  }

  private initServices(
    accessToken: string | null,
    refreshToken: string | null,
  ) {
    const httpClient = this.createHttpClient(accessToken ?? undefined, refreshToken ?? undefined);
    const rabotHttpClient = this.createRabotHttpClient();
    const services = new Services(httpClient, rabotHttpClient);
    this.services = services;
    return services;
  }

  private resetAuthTokens(
    tokens: { accessToken: string; refreshToken: string },
  ) {
    this.initServices(tokens.accessToken, tokens.refreshToken);
    this.setAccessTokenInStorage(tokens.accessToken);
    this.setRefreshTokenInStorage(tokens.refreshToken);
  }

  private getAccessTokenFromStorage() {
    return this.localStorage.getItem<string>('accessToken');
  }

  private getRefreshTokenFromStorage() {
    return this.localStorage.getItem<string>('refreshToken');
  }

  private setAccessTokenInStorage(accessToken: string) {
    this.localStorage.setItem('accessToken', accessToken);
  }

  private setRefreshTokenInStorage(refreshToken: string) {
    this.localStorage.setItem('refreshToken', refreshToken);
  }

  private setWallet(wallet: TGetRabbleUserResDto) {
    this.wallet = wallet;
  }
}

const pluto = new Pluto();
export default pluto;
