/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import { z } from 'zod';
import { getGlobal } from '../../../global';

import { selectBot } from '../../../global/selectors';

export enum AirdropCategoryEnum {
  REKT_7B = 'REKT_7B',
  COPIUM_PRIME = 'COPIUM_PRIME',
  WAGMI_16 = 'WAGMI_16',
  MOONDUST_MAXIMA = 'MOONDUST_MAXIMA',
  GIGACHAD_GALAXY = 'GIGACHAD_GALAXY',
}

export enum UserTierEnum {
  S = 'S',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
}

export const CalculatedUserStatsTransformer = z.object({
  tgDmCount: z.number().optional(),
  tgBotsCount: z.number().optional(),
  tgGroupsCount: z.number().optional(),
  tgGroupsAdminCount: z.number().optional(),
  tgGroupsMemberCount: z.number().optional(),
  tgGroupsOwnerCount: z.number().optional(),
  tgChannelCount: z.number().optional(),
  tgChannelMemberCount: z.number().optional(),
  tgChannelAdminCount: z.number().optional(),
  tgChannelOwnerCount: z.number().optional(),
  tier: z.nativeEnum(UserTierEnum).optional(),
  tgUserScore: z.number().optional(),
});

export type TCalculatedUserStatsReqDto = z.infer<
  typeof CalculatedUserStatsTransformer
>;

export const calculateUserTier = (score: number): UserTierEnum => {
  if (score < 500) {
    return UserTierEnum.D;
  } else if (score < 3000) {
    return UserTierEnum.C;
  } else if (score < 7000) {
    return UserTierEnum.B;
  } else if (score < 10000) {
    return UserTierEnum.A;
  } else {
    return UserTierEnum.S;
  }
};

export const calculateUserStats = (
  dialogs: any[],
): TCalculatedUserStatsReqDto => {
  const global = getGlobal();
  const dms = dialogs.filter((dialog) => dialog.type === 'chatTypePrivate');

  const bots = dms.filter((dialog) => {
    const isBot = selectBot((global), dialog.id);

    return isBot?.type === 'userTypeBot';
  });

  const groups = dialogs.filter((dialog) => dialog.type === 'chatTypeBasicGroup');
  const channels = dialogs.filter((dialog) => dialog.type === 'chatTypeChannel' || dialog.type === 'chatTypeSuperGroup');

  const memberGroups = groups.filter(
    (dialog) => (dialog as any)?.adminRights === null
      || !(dialog as any)?.isCreator,
  );
  const adminGroups = groups.filter(
    (dialog) => !!(dialog as any)?.adminRights && !(dialog as any)?.isCreator,
  );

  const ownerGroups = groups.filter(
    (dialog) => !!(dialog as any)?.isCreator,
  );
  const memberChannels = channels.filter(
    (dialog) => (dialog as any)?.adminRights === null
      || !(dialog as any)?.isCreator,
  );
  const adminChannels = channels.filter(
    (dialog) => !!(dialog as any)?.adminRights,
  );

  const ownerChanels = channels.filter(
    (dialog) => !!(dialog as any)?.isCreator,

  );

  const OwnerOrAdminChannels = channels.filter(
    (dialog) => !!(dialog as any)?.isCreator || !!(dialog as any)?.adminRights,
  );

  const dmsCount = dms?.length || 0;
  const groupsCount = groups?.length || 0;
  const memberGroupsCount = memberGroups?.length || 0;
  const adminGroupsCount = adminGroups?.length || 0;
  const ownerGroupsCount = ownerGroups?.length || 0;
  const channelsCount = channels?.length || 0;
  const memberChannelsCount = memberChannels?.length || 0;
  const adminChannelsCount = adminChannels?.length || 0;
  const ownerChannelsCount = ownerChanels?.length || 0;
  const OwnerOrAdminChannelsCount = OwnerOrAdminChannels?.length || 0;
  const botsCount = bots?.length || 0;

  const telegramScore = dialogs?.length;

  // const tier = calculateUserTier(eventScore);
  // console.log('tier', tier);

  return {
    tgDmCount: dmsCount,
    tgBotsCount: botsCount,
    tgGroupsCount: groupsCount,
    tgGroupsAdminCount: adminGroupsCount,
    tgGroupsOwnerCount: ownerGroupsCount,
    tgGroupsMemberCount: memberGroupsCount,
    tgChannelCount: channelsCount,
    tgChannelMemberCount: memberChannelsCount,
    tgChannelAdminCount: adminChannelsCount,
    tgChannelOwnerCount: ownerChannelsCount,
    // tier,
    tgUserScore: telegramScore,
  };
};

export const calculateWalletScore = (transactionCount: number) => {
  return transactionCount;
};

export const calculateUserScore = (stats: TCalculatedUserStatsReqDto): number => {
  const memberGroupsCount = stats?.tgGroupsMemberCount || 0;
  const adminGroupsCount = (stats?.tgGroupsAdminCount || 0) + (stats?.tgGroupsOwnerCount || 0);

  const score = memberGroupsCount + 2 * adminGroupsCount;

  return score;
};
