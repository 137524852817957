import type { HttpClient } from '../http';
import type {
  TAirdropEarnNftReqDto,
  TCreateEventReqDto,
  TGetInviteAcceptedCount,
  TGetLeaderboardResDto,
  TGetLoginStreakResDto,
  TGetUserRankResDto,
  TUploadEarnStatsReqDto,
} from './dtos';

export class EarnService {
  constructor(private readonly httpClient: HttpClient) {}

  public async getUserEarnStats(
  ): Promise<any> {
    try {
      const result = await this.httpClient.get('/earns/get-stats');
      return result;
    } catch (error) {
      //
    }
    return null;
  }

  public uploadEarnStats({
    payload,
  }: TUploadEarnStatsReqDto) {
    return this.httpClient.post('/earns/upload-stats', {
      payload,
    });
  }

  public airdropNft({
    category,
  }: TAirdropEarnNftReqDto) {
    return this.httpClient.post('/earns/airdrop-nft', {
      category,
    });
  }

  public getLeaderboard(): Promise<TGetLeaderboardResDto> {
    return this.httpClient.get('/earns/leaderboard');
  }

  public getUserRank(): Promise<TGetUserRankResDto> {
    return this.httpClient.get('/earns/user-rank');
  }

  public createEvent({ event } : TCreateEventReqDto): Promise<void> {
    return this.httpClient.post('/event', {
      event,
    });
  }

  public getAppVisitCount(): Promise<TGetLoginStreakResDto> {
    return this.httpClient.get('/earns/login-streak');
  }

  public getInviteAcceptedCount(): Promise<TGetInviteAcceptedCount> {
    return this.httpClient.get('/earns/invite-count');
  }
}
